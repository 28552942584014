import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

const Careers = () => {
  return (
    <>
    <Header color={"black"}/>
    <div className="page-wrapper">
    

    <div className="main-containers">
      <section className="hero-sections">
        <h1>COMING SOON!!!</h1>
        
      </section>
    </div>
    </div>
  <Footer/>
    </>
   
  )
}

export default Careers